// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  tchala:'/tchala'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  fiche_view: (refCode) => `/fiche-view/${refCode}`,
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  borlette: {
    root: path(ROOTS_DASHBOARD, '/borlette'),
    blocage_boule: path(ROOTS_DASHBOARD, '/borlette/blocage-boule'),
    add_blocage_boule: path(ROOTS_DASHBOARD, '/borlette/blocage-boule/new'),
    del_blocage_boule: path(ROOTS_DASHBOARD, '/borlette/blocage-boule/delete'),
    
    lotto: path(ROOTS_DASHBOARD, '/borlette/lotto'),
    add_lotto: path(ROOTS_DASHBOARD, '/borlette/lotto/new'),
    statistique: path(ROOTS_DASHBOARD, '/borlette/statistiques'),
    list: path(ROOTS_DASHBOARD, '/borlette/list-fiches'),
    list_tirage: path(ROOTS_DASHBOARD, '/borlette/list-fiches-tirages'),
    list_fgagnant: path(ROOTS_DASHBOARD, '/borlette/list-fiches-gagnants'),
    list_fsupp: path(ROOTS_DASHBOARD, '/borlette/list-fiches-supprimer')
  },
  configuration_borlette: {
    root: path(ROOTS_DASHBOARD, '/borlette-configuration'),
    limit_intervalle: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-intervalle-option'),
    add_limit_intervalle: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-intervalle-option/new'),
    type_game: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game'),
    add_type_game: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game/new'),
    type_game_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game-per-agent'),
    add_type_game_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game-per-agent/new'),
    type_boule: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule'),
    add_type_boule: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule/new'),
    type_boule_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule-per-agent'),
    add_type_boule_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule-per-agent/new')
  },
  limits: {
    root: path(ROOTS_DASHBOARD, '/limit-configuration'),
    limit_ab: path(ROOTS_DASHBOARD, '/limit-configuration/100-boules'),
    limit_ab_list: path(ROOTS_DASHBOARD, '/limit-configuration/100-boules/list'),
    edit_limit_ab_list: (lim) => path(ROOTS_DASHBOARD, `/limit-configuration/100-boules/${lim}/edit`),
    limit_bp: path(ROOTS_DASHBOARD, '/limit-configuration/boules-paires'),

    limit_bp: path(ROOTS_DASHBOARD, '/limit-configuration/boules-paires'),
    limit_bp_list: path(ROOTS_DASHBOARD, '/limit-configuration/boules-paires/list'),
    edit_limit_bp_list: (lim) => path(ROOTS_DASHBOARD, `/limit-configuration/boules-paires/${lim}/edit`),
    limit_bp: path(ROOTS_DASHBOARD, '/limit-configuration/boules-paires'),

    limit_bpt: path(ROOTS_DASHBOARD, '/limit-configuration/boules-points'),
    limit_bpt_list: path(ROOTS_DASHBOARD, '/limit-configuration/boules-points/list'),
    edit_limit_bpt_list: (lim) => path(ROOTS_DASHBOARD, `/limit-configuration/boules-points/${lim}/edit`),
    limit_bpt: path(ROOTS_DASHBOARD, '/limit-configuration/boules-points'),
  },
  tirage: {
    root: path(ROOTS_DASHBOARD, '/tirage'),
    newTirage: path(ROOTS_DASHBOARD, '/tirage/new'),
    newTirageAuto: path(ROOTS_DASHBOARD, '/tirage/automatique'),
    list: path(ROOTS_DASHBOARD, '/tirage/list'),
    configuration_list: path(ROOTS_DASHBOARD, '/tirage/configuration/generale/list'),
    configuration: path(ROOTS_DASHBOARD, '/tirage/configuration/generale'),
    edit_configuration_gen: (configurationId) => path(ROOTS_DASHBOARD, `/tirage/configuration/generale/${configurationId}/edit`),
    configuration_type: (type) => path(ROOTS_DASHBOARD, `/tirage/configuration/${type}`),
    edit_configuration: (type, configurationId) =>
      path(ROOTS_DASHBOARD, `/tirage/configuration/${type}/${configurationId}/edit`),
    add_configuration: (type) => path(ROOTS_DASHBOARD, `/tirage/configuration/${type}/new`),
    info_tirage: path(ROOTS_DASHBOARD, '/tirage/infotirage'),
    add_info_tirage: path(ROOTS_DASHBOARD, '/tirage/infotirage/new')
  },
  tirageStatus: {
    root: path(ROOTS_DASHBOARD, '/tirageStatus'),
    list: path(ROOTS_DASHBOARD, '/tirageStatus/list'),
  },
  tirageStatus2: {
    root: path(ROOTS_DASHBOARD, '/tirageStatus2'),
    list: path(ROOTS_DASHBOARD, '/tirageStatus2/list'),
  },
  rapport: {
    root: path(ROOTS_DASHBOARD, '/rapports/root'),
    journalier: path(ROOTS_DASHBOARD, '/rapports/journalier'),
    agent: path(ROOTS_DASHBOARD, '/rapports/agent'),
    tirage: path(ROOTS_DASHBOARD, '/rapports/tirage'),
    avantTirage: path(ROOTS_DASHBOARD, '/rapports/avantTirage')
  },
  transaction: {
    root: path(ROOTS_DASHBOARD, '/transactions'),
    new: path(ROOTS_DASHBOARD, '/transactions/new')
  },
  agent: {
    root: path(ROOTS_DASHBOARD, '/agent'),
    list: path(ROOTS_DASHBOARD, '/agent/list'),
    newAgent: path(ROOTS_DASHBOARD, '/agent/new')
  },
  delete_fiche: {
    root: path(ROOTS_DASHBOARD, '/delete_fiche'),
    list: path(ROOTS_DASHBOARD, '/delete_fiche/list'),
   
  },
  company: {
    root: path(ROOTS_DASHBOARD, '/company'),
    list: path(ROOTS_DASHBOARD, '/company/list'),
    newCompany: path(ROOTS_DASHBOARD, '/company/new'),
    companyPayment: path(ROOTS_DASHBOARD, '/company/payment'),
    companyFactu: path(ROOTS_DASHBOARD, '/company/facturation'),
    companyStatus: path(ROOTS_DASHBOARD, '/company/companyStatus'),
    newCompanyPayment: path(ROOTS_DASHBOARD, '/company/payment/new')
  },
  generale: {
    root: path(ROOTS_DASHBOARD, '/generale'),
    list: path(ROOTS_DASHBOARD, '/generale/list'),
    newGenerale: path(ROOTS_DASHBOARD, '/generale/new')
  },
  supervisor: {
    root: path(ROOTS_DASHBOARD, '/supervisor'),
    list: path(ROOTS_DASHBOARD, '/supervisor/list'),
    newSupervisor: path(ROOTS_DASHBOARD, '/supervisor/new')
  },
  surcussale: {
    root: path(ROOTS_DASHBOARD, '/surcussale'),
    list: path(ROOTS_DASHBOARD, '/surcussale/list'),
    newSurcussale: path(ROOTS_DASHBOARD, '/surcussale/new')
  },
  
  tchala: {
    root: path(ROOTS_DASHBOARD, '/tchala'),
    list: path(ROOTS_DASHBOARD, '/tchala/list'),
    new: path(ROOTS_DASHBOARD, '/tchala/new'),
   
  },

  mariage: {
    root: path(ROOTS_DASHBOARD, '/mariage'),
    list: path(ROOTS_DASHBOARD, '/mariage/list'),
    newMariage: path(ROOTS_DASHBOARD, '/mariage/new')
  },
  amountmariageAgent: {
    root: path(ROOTS_DASHBOARD, '/amountmariageAgent'),
    list: path(ROOTS_DASHBOARD, '/amountmariageAgent/list'),
    newAmountMariage: path(ROOTS_DASHBOARD, '/amountmariageAgent/new')
  },
  mariageSurcussale: {
    root: path(ROOTS_DASHBOARD, '/mariageSurcussale'),
    list: path(ROOTS_DASHBOARD, '/mariageSurcussale/list'),
    newMariageSurc: path(ROOTS_DASHBOARD, '/mariageSurcussale/new')
  },
  
  amountmariageSurcussale: {
    root: path(ROOTS_DASHBOARD, '/amountmariageSurcussale'),
    list: path(ROOTS_DASHBOARD, '/amountmariageSurcussale/list'),
    newamountMariageSurc: path(ROOTS_DASHBOARD, '/amountmariageSurcussale/new')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    generateAgent: path(ROOTS_DASHBOARD, '/user/generate-agent'),
    generateCompany: path(ROOTS_DASHBOARD, '/user/generate-company'),

    tracabilite_company: path(ROOTS_DASHBOARD, '/user/tracabilite-company'),
    tracabilite_agent: path(ROOTS_DASHBOARD, '/user/tracabilite-agent'),
    tracabilite_supervisor: path(ROOTS_DASHBOARD, '/user/tracabilite-supervisor'),
    promote: path(ROOTS_DASHBOARD, '/user/message-push'),
    newPromote: path(ROOTS_DASHBOARD, '/user/message-push/new'),
    country: path(ROOTS_DASHBOARD, '/user/pays'),
    newCountry: path(ROOTS_DASHBOARD, '/user/pays/new')
  }
};
