/* eslint-disable */

import axios from './axios';
import { format } from 'date-fns';
// ----------------------------------------------------------------------

async function FakeRequest(time) {
  return new Promise((res) => setTimeout(res, time));
}

async function handleModifyProfile(data) {
  const response = await axios.put('/api/companies/user', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}

async function handleAddAgent(data) {
  const response = await axios.post('/api/companies/agent', data);
  return response.data;
}

async function handleAddCompanyGenerate(data) {
  const response = await axios.post('/api/generals/generate-company', data);
  return response.data;
}

async function handleAddAgentGGenerate(data) {
  const response = await axios.post('/api/companies/generate-agent', data);
  return response.data;
}

async function handleClearAgent(data) {
  const response = await axios.post(
    `/api/companies/list-agent?start_date=${data.datedebut}&end_date=${data.datefin}`,
    data
  );
  return response.data;
}

async function handleAddTransactionZero(data) {
  const response = await axios.post('/api/companies/transaction-zero', data);
  return response.data;
}

async function handleDeleteTransactionZero(agentid, transactionid) {
  const response = await axios.delete(
    `/api/companies/transaction-zero?agent_id=${agentid}&transaction_id=${transactionid}`
  );
  return response.data;
}

async function handleModifyAgent(data) {
  const response = await axios.put('/api/companies/agent', data);
  return response.data;
}

async function handleDeleteAgent(data) {
  const response = await axios.delete('/api/companies/agent', { data });
  return response.data;
}

async function handleAddSupervisor(data) {
  try {
    const response = await axios.post('/api/companies/supervisor', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
    throw error;
  }
}

async function handleModifySupervisor(data) {
  try {
    const response = await axios.put('/api/companies/supervisor', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
    throw error;
  }
}

async function handleBlockSupervisor(data) {
  try {
    const response = await axios.post('/api/companies/block-supervisor', data);
    return response.data;
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
    throw error;
  }
}

async function handleDeleteSupervisor(data) {
  const response = await axios.delete('/api/companies/delete-supervisor', { data });
  return response.data;
}

async function handleAddMessage(data) {
  const response = await axios.post('/api/companies/messages', data);
  return response.data;
}

async function handleModifyMessage(data) {
  const response = await axios.put('/api/companies/messages', data);
  return response.data;
}

async function handleDeleteMessage(data) {
  const response = await axios.delete('/api/companies/messages', { data });
  return response.data;
}

async function handleAddCompanyPayment(data) {
  const response = await axios.post('/api/companies/historics-payment', data);
  return response.data;
}

async function handleModifyCompanyPayment(data) {
  const response = await axios.put('/api/companies/historics-payment', data);
  return response.data;
}

async function handleModifyCompanyPaid(data) {
  const response = await axios.post('/api/companies/company-paid', data);
  return response.data;
}

async function handleDeleteCompanyPayment(data) {
  const response = await axios.delete('/api/companies/historics-payment', { data });
  return response.data;
}

async function handleAddAuto(data) {
  const response = await axios.post('/api/generals/tirage-auto', data);
  return response.data;
}

async function handleAddCountry(data) {
  const response = await axios.post('/api/generals/countries', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}

async function handleModifyCountry(data) {
  const response = await axios.put('/api/generals/countries', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}

async function handleDeleteCountry(data) {
  const response = await axios.delete('/api/generals/delete-country', { data });
  return response.data;
}

async function handleAddCompagnie(data) {
  const response = await axios.post('/api/generals/compagnies', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}

async function handleModifyCompagnie(data) {
  const response = await axios.put('/api/generals/compagnies', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}

async function handleDeleteCompagnie(data) {
  const response = await axios.delete('/api/generals/delete-company', { data });
  return response.data;
}

async function handleAddSurcussale(data) {
  const response = await axios.post('/api/companies/surcussale', data);
  return response.data;
}

async function handleAddLotto(data) {
  const response = await axios.post('/api/companies/lotto', data);
  return response.data;
}

async function handleModifyLotto(data) {
  const response = await axios.put('/api/companies/lotto', data);
  return response.data;
}

async function handleDeleteLotto(data) {
  const response = await axios.delete('/api/companies/lotto', { data });
  return response.data;
}

async function handleModifySurcussale(data) {
  const response = await axios.put('/api/companies/surcussale', data);
  return response.data;
}

async function handleBlockSurcussale(data) {
  try {
    const response = await axios.post('/api/companies/block-surcussale', data);
    return response.data;
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
    throw error;
  }
}

async function handleAddTirage(data) {
  const response = await axios.post('/api/companies/tirage', data);
  return response.data;
}

async function handleModifyTirage(data) {
  const response = await axios.put('/api/companies/tirage', data);
  return response.data;
}

async function handleDeleteTirage(tirageId) {
  const response = await axios.delete(`/api/companies/tirage?tirageId=${tirageId}`);
  return response.data;
}

async function handleAddInfoTirage(data) {
  const response = await axios.post('/api/companies/info-tirage', data);
  return response.data;
}

async function handleModifyInfoTirage(data) {
  const response = await axios.put('/api/companies/info-tirage', data);
  return response.data;
}

async function handleDeleteInfoTirage(data) {
  const response = await axios.delete('/api/companies/info-tirage', { data });
  return response.data;
}

async function handleAddTypeGame(data) {
  const response = await axios.post('/api/companies/type-games', data);
  return response.data;
}

async function handleModifyTypeGame(data) {
  const response = await axios.put('/api/companies/type-games', data);
  return response.data;
}

async function handleAddTypeBoule(data) {
  const response = await axios.post('/api/companies/type-boules', data);
  return response.data;
}

async function handleModifyTypeBoule(data) {
  const response = await axios.put('/api/companies/type-boules', data);
  return response.data;
}

async function handleAddTypeGameAgent(data) {
  const response = await axios.post('/api/companies/type-games-agent', data);
  return response.data;
}

async function handleAddIntervalleOption(data) {
  const response = await axios.post('/api/companies/intervalle-option', data);
  return response.data;
}

async function handleModifyIntervalleOption(data) {
  const response = await axios.put('/api/companies/intervalle-option', data);
  return response.data;
}

async function handleModifyTypeGameAgent(data) {
  const response = await axios.put('/api/companies/type-games-agent', data);
  return response.data;
}

async function handleAddTypeBouleAgent(data) {
  const response = await axios.post('/api/companies/type-boules-agent', data);
  return response.data;
}

async function handleModifyTypeBouleAgent(data) {
  const response = await axios.put('/api/companies/type-boules-agent', data);
  return response.data;
}

async function handleAddBouleBlock(data) {
  const response = await axios.post('/api/companies/boules-block', data);
  return response.data;
}



async function handleModifyBouleBlock(data) {
  const response = await axios.put('/api/companies/boules-block', data);
  return response.data;
}

// ///////////////////////////////
async function handleAddMariageAgent(data) {
  const response = await axios.post('/api/companies/prime-mariage-agent', data);
  return response.data;
}

async function handleModifyMariageAgent(data) {
  const response = await axios.put('/api/companies/prime-mariage-agent', data);
  return response.data;
}

async function handleAddMariageSurc(data) {
  const response = await axios.post('/api/companies/prime-mariage-surcussale', data);
  return response.data;
}

async function handleModifyMariageSurc(data) {
  const response = await axios.put('/api/companies/prime-mariage-surcussale', data);
  return response.data;
}




async function handleAddAmountMariageAgent(data) {
  const response = await axios.post('/api/companies/amount-mariage-agent', data);
  return response.data;
}

async function handleModifyAmountMariageAgent(data) {
  const response = await axios.put('/api/companies/amount-mariage-agent', data);
  return response.data;
}

async function handleAddAmountMariageSurc(data) {
  const response = await axios.post('/api/companies/amount-mariage-surcussale', data);
  return response.data;
}

async function handleModifyAmountMariageSurc(data) {
  const response = await axios.put('/api/companies/amount-mariage-surcussale', data);
  return response.data;
}

// ////////////////////////////////////////////

async function handleAddMariageSurcussale(data) {
  const response = await axios.post('/api/companies/prime-mariage-surcussale', data);
  return response.data;
}

async function handleModifyMariageSurcussale(data) {
  const response = await axios.put('/api/companies/prime-mariage-surcussale', data);
  return response.data;
}
// ///////////////////////////////





async function handlePostTirageConf(data) {
  const response = await axios.post('/api/companies/prime-tirage', data);
  return response.data;
}

async function handlePostSuccConf(data) {
  const response = await axios.post('/api/companies/prime-surcussale', data);
  return response.data;
}

async function handlePostAgentConf(data) {
  const response = await axios.post('/api/companies/prime-agent', data);
  return response.data;
}

async function handleDeletePrime(data, type) {
  console.log('Prime-type: ', type, data);
  const response = await axios.delete(`/api/companies/prime-${type}`, { data });
  return response.data;
}

async function handleUpdatePrimeConf(data) {
  const response = await axios.put('/api/companies/prime', data);
  return response.data;
}

async function handleUpdateTirageConf(data) {
  const response = await axios.put('/api/companies/prime-tirage', data);
  return response.data;
}
async function handleUpdateSuccConf(data) {
  const response = await axios.put('/api/companies/prime-surcussale', data);
  return response.data;
}

async function handleUpdateAgentConf(data) {
  const response = await axios.put('/api/companies/prime-agent', data);
  return response.data;
}

async function handleUpdateFiche(data) {
  const response = await axios.put('/api/companies/fiche', data);
  return response.data;
}

async function handleDeleteTypeGames(data) {
  const response = await axios.delete('/api/companies/type-games', { data });
  return response.data;
}

async function handleDeleteIntervalleOptions(data) {
  const response = await axios.delete('/api/companies/intervalle-option', { data });
  return response.data;
}

async function handleDeleteTypeGameAgents(data) {
  const response = await axios.delete('/api/companies/type-games-agent', { data });
  return response.data;
}

async function handleDeleteTypeBoules(data) {
  const response = await axios.delete('/api/companies/type-boules', { data });
  return response.data;
}

async function handleDeleteTypeBouleAgents(data) {
  const response = await axios.delete('/api/companies/type-boules-agent', { data });
  return response.data;
}

async function handleDeleteSurcussale(data) {
  const response = await axios.delete('/api/companies/surcussale', { data });
  return response.data;
}

async function handleAddTransaction(data) {
  const response = await axios.post('/api/companies/transactions', data);
  return response.data;
}

async function handleChooseCompany(data) {
  const response = await axios.post('/api/generals/company', data);
  return response.data;
}

async function handleChooseCountry(data) {
  const response = await axios.post('/api/generals/country', data);
  return response.data;
}

async function handleUpdateLimitAllBoule(data) {
  const response = await axios.put('/api/companies/limit-100-boules', data);
  return response.data;
}


async function handleUpdateLimitBoulePaire(data) {
  const response = await axios.put('/api/companies/limit-boules-paires', data);
  return response.data;
}

async function gettchalaList() {
  const response = await axios.get('/api/tchalas', );
  return response.data;
}

async function handlePostTchala(data) {
  const response = await axios.post('/api/tchalas/', data);
  return response.data;
}




async function handleDeleteFicheRange(agentIds, startDate, endDate) {
  try {
    const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
    const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');

    const response = await axios.delete(
      `/api/companies/drop-fiches?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
      {
        data: {
          agent_ids: agentIds,  // On envoie les agent_ids dans le body
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur de suppression:", error);
    throw error;
  }
}

export default {
  handleUpdateLimitBoulePaire,
  handleDeleteFicheRange,
  FakeRequest,
  handleModifyProfile,
  handleAddAgent,
  handleModifyAgent,
  handleDeleteAgent,
  handleAddSupervisor,
  handleModifySupervisor,
  handleBlockSupervisor,
  handleDeleteSupervisor,
  handleAddSurcussale,
  handleModifySurcussale,
  handleBlockSurcussale,
  handleAddLotto,
  handleModifyLotto,
  handleDeleteLotto,
  handleAddTirage,
  handleModifyTirage,
  handleDeleteTirage,
  handleAddInfoTirage,
  handleModifyInfoTirage,
  handleDeleteInfoTirage,
  handleAddBouleBlock,
  handleModifyBouleBlock,
  handlePostTirageConf,
  handlePostSuccConf,
  handlePostAgentConf,
  handleUpdatePrimeConf,
  handleUpdateTirageConf,
  handleUpdateSuccConf,
  handleUpdateAgentConf,
  handleDeletePrime,
  handleAddTypeGame,
  handleModifyTypeGame,
  handleAddTypeBoule,
  handleModifyTypeBoule,
  handleAddTypeGameAgent,
  handleModifyTypeGameAgent,
  handleAddIntervalleOption,
  handleModifyIntervalleOption,
  handleAddTypeBouleAgent,
  handleModifyTypeBouleAgent,
  handleUpdateFiche,
  handleDeleteTypeGames,
  handleDeleteTypeGameAgents,
  handleDeleteIntervalleOptions,
  handleDeleteTypeBoules,
  handleDeleteTypeBouleAgents,
  handleDeleteSurcussale,
  handleAddTransaction,
  handleChooseCompany,
  handleChooseCountry,
  handleAddMessage,
  handleModifyMessage,
  handleDeleteMessage,
  handleAddCountry,
  handleModifyCountry,
  handleDeleteCountry,
  handleAddCompagnie,
  handleModifyCompagnie,
  handleDeleteCompagnie,
  handleAddCompanyPayment,
  handleModifyCompanyPayment,
  handleDeleteCompanyPayment,
  handleAddAuto,
  handleAddAgentGGenerate,
  handleAddCompanyGenerate,
  handleClearAgent,
  handleAddTransactionZero,
  handleDeleteTransactionZero,
  handleUpdateLimitAllBoule,
  handleAddMariageAgent,
  handleModifyMariageAgent,
  handleAddMariageSurcussale,
  handleModifyMariageSurcussale,
  handleAddMariageSurc,
  handleModifyMariageSurc,
  handleModifyAmountMariageSurc,
  handleAddAmountMariageSurc,
  handleModifyAmountMariageAgent,
  handleAddAmountMariageAgent,

  gettchalaList,
  handlePostTchala,
  handleModifyCompanyPaid

};
